<template>
    <el-form :model="dataSource" status-icon :rules="pwdValidateRule" ref="_pwdInfoForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="原密码" prop="oldpwd">
            <el-input type="password" v-model="dataSource.UserPassword" auto-complete="off" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newpwd">
            <el-input type="password" v-model="dataSource.NewPassword" :maxlength="32" :minlength="8" auto-complete="off" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repwd">
            <el-input type="password" v-model="dataSource.ConfirmPassword" :maxlength="32" :minlength="8" auto-complete="off" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <!--<el-form-item>
            <el-button type="primary" @click="submitForm('dataSource')">提交</el-button>
            <el-button @click="resetForm('dataSource')">重置</el-button>
        </el-form-item>-->
    </el-form>
</template>
<script>
    import config from '@/scripts/config.js'
    export default {
        data() {
            var validateOldPwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入原密码'));
                } else {
                    //发送请求验证原密码是否正确
                    var param = { UserPassword: this.dataSource.UserPassword };
                    param.UserPassword = this.$crypto.sm2Encrypt(param.UserPassword);
                    this.$ajax.send("omsapi/sysuser/validatepwd", "post", param, (data) => {

                    }, error => {
                        callback(new Error('原密码错误'));
                    });

                    //if (this.dataSource.NewPassword !== '') {
                    //    this.$refs._pwdInfoForm.validateField('newpwd');
                    //}
                    callback();
                }
            };

            var validateNewPwd = (rule, value, callback) => {
                if (this.dataSource.NewPassword === '') {
                    callback(new Error('请输入新密码'));
                } else {
                    //if (value.length < 6)
                    //    callback(new Error('新密码长度不得小于6位'));

                    if (this.dataSource.NewPassword.length < 8) {
                        callback(new Error('密码至少八位或者八位以上'))
                    }
                    var patrn = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
                    if (!patrn.exec(this.dataSource.NewPassword)) callback(new Error('密码必须包含数字，字母，特殊字符'))
                    if (this.dataSource.UserPassword === this.dataSource.NewPassword) {
                        callback(new Error('新密码与原密码不能相同!'));
                    }
                    if (this.dataSource.NewPassword !== '') {
                        this.$refs._pwdInfoForm.validateField('repwd');
                    }
                    callback();
                }
            };

            var validateRePwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (this.dataSource.ConfirmPassword !== this.dataSource.NewPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                dataSource: {
                    UserPassword: '',
                    NewPassword: '',
                    ConfirmPassword: ''
                },
                pwdValidateRule: {
                    oldpwd: [
                        { validator: validateOldPwd, trigger: 'blur' }
                    ],
                    newpwd: [
                        { validator: validateNewPwd, trigger: 'blur' }
                    ],
                    repwd: [
                        { validator: validateRePwd, trigger: 'blur' }
                    ]
                }
            };
        },
        methods: {
            changePwd(options) {
                var _this = this;
                _this.$refs["_pwdInfoForm"].validate((valid) => {
                    if (valid) {
                        var param = _this.dataSource;
                        param.UserPassword = _this.$crypto.sm2Encrypt(param.UserPassword);
                        param.NewPassword = _this.$crypto.sm2Encrypt(param.NewPassword);
                        param.ConfirmPassword = _this.$crypto.sm2Encrypt(param.ConfirmPassword);
                        _this.$ajax.send("omsapi/sysuser/changepwd", "post", param, (data) => {
                            _this.Utils.messageBox("密码修改成功.", "success");
                            _this.resetForm();
                        }, error => {
                            _this.Utils.messageBox(error, "error");
                            _this.resetForm();
                            options.visible = true;
                        });
                    } else {
                        options.visible = true;
                        return false;
                    }
                });
            },
            resetForm() {
                this.dataSource.UserPassword = '';
                this.dataSource.NewPassword = '';
                this.dataSource.ConfirmPassword = '';
                //this.$refs["_pwdInfoForm"].resetFields();
            }
        }
    }
</script>
<style scoped>
</style>